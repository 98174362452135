<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="elv-transactions-screening-events-item">
    <div v-if="dropDownData.length" class="elv-transactions-screening-events-purpose">
      <div v-for="(item, index) in dropDownData" :key="index">
        <div
          v-if="!currentPurposeIsEmpty(item.children)"
          class="elv-transactions-screening-events-purpose__item"
          :class="{ 'elv-purpose-is-checked': selectedMenu === item?.groupName }"
        >
          <el-checkbox
            :model-value="currentPurposeCheckAll(item.groupName, item.children)"
            :indeterminate="
              !currentPurposeCheckAll(item.groupName, item.children) &&
              currentPurposeCheckAll(item.groupName, item.children, 'some')
            "
            @change="onChangePurposeAll(item.groupName, item.children, $event)"
          ></el-checkbox>
          <p @click.prevent="onCheckPurposeMenu(item)">
            {{ utils.formatLanguageContent(item?.groupName)
            }}<SvgIcon
              v-if="item.groupName !== 'Ignore' && item.groupName !== 'No set' && item.groupName !== 'No contacts'"
              name="events-arrow"
              width="18"
              height="18"
            />
          </p>
        </div>
      </div>
    </div>
    <el-scrollbar v-if="dropDownData.length" max-height="250">
      <template v-for="(item, index) in dropDownData" :key="index">
        <el-checkbox-group v-if="selectedMenu === item?.groupName" v-model="checkedEvents" @change="onCheckedChange">
          <div v-for="(children, i) in item?.children" :key="i">
            <p v-if="props.type === 'events' && children.list.length" class="elv-transactions-screening-event-title">
              {{ utils.formatLanguageContent(children.secondGroupName) }}
            </p>
            <el-checkbox
              v-for="eventItem in children.list"
              :key="
                item.groupName !== 'Unnamed' || eventItem?.name === 'Other'
                  ? eventItem.id
                  : `${eventItem.address}-${eventItem?.platformId}`
              "
              :label="item.groupName !== 'Unnamed' || eventItem?.name === 'Other' ? eventItem.id : eventItem"
              :class="{
                'elv-purpose-is-checked': currentCheckedEvent?.categoryName === eventItem.name && !showCheckboxInput
              }"
            >
              <span v-if="showCheckboxInput">
                <template v-if="item.groupName !== 'Unnamed'"> {{ eventItem.name }}</template>
                <template v-else>
                  <img
                    v-if="eventItem?.platform?.logo"
                    :src="eventItem?.platform?.logo"
                    :alt="eventItem?.platform?.name"
                  />{{ eventItem?.platformId ? utils.middleEllipsis(eventItem?.address) : eventItem.name
                  }}<template v-if="!eventItem.name">({{ eventItem?.count }}) </template>
                </template>
              </span>
              <span v-else @click.prevent="onCheckEvent(eventItem)">{{
                utils.formatLanguageContent(eventItem.name)
              }}</span>
            </el-checkbox>
          </div>
        </el-checkbox-group>
      </template>
    </el-scrollbar>
  </div>
</template>

<script setup lang="ts">
import {
  map,
  find,
  uniq,
  some,
  every,
  filter,
  flatMap,
  concat,
  isEmpty,
  includes,
  isNumber,
  isObject,
  difference
} from 'lodash-es'
import utils from '@/lib/utils'
import { emitter } from '@/lib/mitt'
import { reportEventList } from '@/config/reports'
import { useReportStore } from '@/stores/modules/reports/index'

const reportStore = useReportStore()

const props = defineProps({
  type: {
    type: String,
    default: 'events' // events || contacts || accounts
  },
  showCheckboxInput: {
    type: Boolean,
    default: true
  },
  isShowEditDrawer: {
    type: Boolean,
    default: false
  },
  dropDownData: {
    type: Array<any>,
    required: true
  },
  currentData: {
    type: Object,
    default: () => {}
  }
})

const route = useRoute()
const emit = defineEmits(['onCloseEventSelect'])

const minWidth = computed(() => {
  return props.type === 'events' ? '300px' : '290px'
})

const purposeWidth = computed(() => {
  return props.type === 'events' ? '179px' : '140px'
})

const purposeHeight = computed(() => {
  return props.type === 'events' ? '162px' : '200px'
})

const checkboxInputDisplay = computed(() => {
  return props.showCheckboxInput ? 'flex' : 'none'
})
const selectedMenu = ref('')

const currentCheckedEvent = ref()
const checkedEvents: any = ref([])

/**
 * @description: 当前科目分类是否全选或者分类下是否存在选择
 * @param {*} purposeItem
 * @param {string} type every || some
 * @return {boolean}
 */
const currentPurposeCheckAll = computed(() => (groupName: string, purposeItem: any, type: string = 'every') => {
  let checkedData = []
  if (groupName === 'Unnamed') {
    checkedData = map(flatMap(purposeItem, 'list'), (item: any) => {
      return item?.platformId ? item : item.id
    })
  } else {
    checkedData = map(flatMap(purposeItem, 'list'), 'id')
  }
  let result = false
  if (type === 'every') {
    result = every(checkedData, (value: any) => includes(checkedEvents.value, value))
  } else {
    result = some(checkedData, (value: any) => includes(checkedEvents.value, value))
  }
  return result
})

const currentPurposeIsEmpty = computed(() => (purposeItem: any) => {
  const ids = map(flatMap(purposeItem, 'list'), 'id')
  return ids?.length === 0
})

/**
 * @description: 是否全选
 * @param {*} purposeItem 当前分类数据
 * @param {boolean} val 是否全选
 */
const onChangePurposeAll = (groupName: string, purposeItem: any, val: any) => {
  let checkedData = []
  if (groupName === 'Unnamed') {
    checkedData = map(flatMap(purposeItem, 'list'), (item: any) => {
      return item?.platformId ? item : item.id
    })
  } else {
    checkedData = map(flatMap(purposeItem, 'list'), 'id')
  }
  if (val) {
    const differenceArray = difference(checkedData, checkedEvents.value)
    checkedEvents.value = concat(checkedEvents.value, differenceArray)
  } else {
    checkedEvents.value = difference(checkedEvents.value, checkedData)
  }
  if (props.type === 'events') {
    reportStore.transactionFilter.categoryNos = checkedEvents.value
  } else {
    const contact: any = {
      contactIds: [], // 这个只筛选有的联系人
      unnamedList: [], // 这个只筛选没有联系人的
      unnamedOther: true,
      noContacts: true
    }
    contact.contactIds = checkedEvents.value.filter((item: any) => isNumber(item) && item !== -1)
    contact.unnamedList = map(
      checkedEvents.value.filter((item: any) => isObject(item)),
      (unnamedItem: any) => {
        return {
          value: unnamedItem.address,
          platformId: unnamedItem.platformId
        }
      }
    )
    contact.unnamedOther = includes(checkedEvents.value, 'other')
    contact.noContacts = includes(checkedEvents.value, -1)
    reportStore.transactionFilter.contact = contact
  }
  reportStore.batchEditSelected = []
  emitter.emit('resetList')
}

/**
 * @description: 切换分类菜单
 */
const onCheckPurposeMenu = (item: any) => {
  if (item.groupName !== 'Ignore' && item.groupName !== 'No set' && item.groupName !== 'No contacts') {
    selectedMenu.value = item.groupName
  }
  if (item.groupName === 'Ignore' && !props.showCheckboxInput) {
    // eslint-disable-next-line prefer-destructuring
    currentCheckedEvent.value = item.children[0].list[0]
    emit('onCloseEventSelect')
  }
}

const onCheckEvent = async (item: any) => {
  currentCheckedEvent.value = item
  emit('onCloseEventSelect')
}

const onSelectedPurposeAll = () => {
  if (props.dropDownData.length && props.type === 'events' && route?.query?.noSet === '1') {
    checkedEvents.value = ['00000']
  } else if (props.dropDownData.length) {
    const list: any = []
    props.dropDownData.forEach((item) => {
      if (item.groupName !== 'Ignore' && item.groupName !== 'Unnamed') {
        const ids: any = map(flatMap(item?.children, 'list'), 'id')
        list.push(...ids)
      }
      if (item.groupName === 'Unnamed') {
        const unnamedList: any = filter(flatMap(item?.children, 'list'), (listItem: any) => !listItem?.name)
        list.push(...unnamedList, 'other')
      }
    })
    checkedEvents.value = list
  }
}

const onCheckedChange = () => {
  if (props.type === 'events') {
    reportStore.transactionFilter.categoryNos = checkedEvents.value
    reportStore.batchEditSelected = []
    emitter.emit('resetList')
  } else {
    const contact: any = {
      contactIds: [], // 这个只筛选有的联系人
      unnamedList: [], // 这个只筛选没有联系人的
      unnamedOther: true,
      noContacts: true
    }
    contact.contactIds = checkedEvents.value.filter((item: any) => isNumber(item) && item !== -1)
    contact.unnamedList = map(
      checkedEvents.value.filter((item: any) => isObject(item)),
      (unnamedItem: any) => {
        return {
          value: unnamedItem.address,
          platformId: unnamedItem.platformId
        }
      }
    )
    contact.unnamedOther = includes(checkedEvents.value, 'other')
    contact.noContacts = includes(checkedEvents.value, -1)
    reportStore.transactionFilter.contact = contact
    reportStore.batchEditSelected = []
    emitter.emit('resetList')
  }
}
const onResetSelectedAll = () => {
  selectedMenu.value = props.dropDownData[0]?.groupName
  onSelectedPurposeAll()
}

onMounted(() => {
  onResetSelectedAll()
})

watch(
  () => route?.query,
  () => {
    if (route?.query?.noSet === '1') {
      onResetSelectedAll()
    }
  }
)

watch(
  () => [reportStore.contactList, reportStore.contactUnnamedList],
  () => {
    if (!isEmpty(props.dropDownData) && props.type === 'contacts') {
      selectedMenu.value = props.dropDownData[0]?.groupName
      onSelectedPurposeAll()
    }
  }
)

watch(
  () => reportStore.accountList,
  () => {
    if (!isEmpty(props.dropDownData) && props.type === 'accounts') {
      selectedMenu.value = props.dropDownData[0]?.groupName
      onSelectedPurposeAll()
    }
  }
)

watch(
  () => props.currentData,
  () => {
    if (!isEmpty(props.currentData) && props.type === 'events') {
      nextTick(() => {
        currentCheckedEvent.value = find(reportEventList, { categoryNo: props.currentData.categoryNo })
        selectedMenu.value =
          currentCheckedEvent.value?.categoryNo === '00000'
            ? reportEventList[0]?.primaryGrouping
            : currentCheckedEvent.value?.primaryGrouping
      })
    } else if (!isEmpty(currentCheckedEvent.value)) {
      currentCheckedEvent.value = {}
    }
  },
  { deep: true, immediate: true }
)

watch(
  () => props.dropDownData,
  () => {
    if (!isEmpty(props.dropDownData) && props.type === 'events' && props.showCheckboxInput) {
      nextTick(() => {
        selectedMenu.value = props.dropDownData[0].groupName
        onSelectedPurposeAll()
        checkedEvents.value = checkedEvents.value.filter((item: string) => {
          const GainLoss = ['99994', '99995', '99996', '99997']
          if (includes(GainLoss, item)) return false
          return true
        })
      })
    }
  },
  { deep: true, immediate: true }
)

watch(
  [() => reportStore.batchEditSelected, () => props.isShowEditDrawer],
  () => {
    if (
      reportStore.batchEditSelected.length &&
      props.type === 'events' &&
      isEmpty(props.currentData) &&
      props.isShowEditDrawer
    ) {
      const isSameCategoryNo = uniq(map(reportStore.batchEditSelected, 'categoryNo')).length === 1
      if (isSameCategoryNo) {
        currentCheckedEvent.value = find(reportEventList, {
          categoryNo: reportStore.batchEditSelected[0].categoryNo
        })
      } else {
        currentCheckedEvent.value = {}
      }
    } else if (!reportStore.batchEditSelected.length && props.type === 'events') {
      currentCheckedEvent.value = {}
    }
  },
  { immediate: true }
)

defineExpose({ currentCheckedEvent, checkedEvents, onSelectedPurposeAll, onResetSelectedAll })
</script>

<style lang="scss">
.el-popover.elv-financials-cell-event-popper,
.el-popover.elv-source-rule-drawer-event-popper,
.el-popover.elv-transactions-review-event-popper,
.el-popover.elv-transactions-drawer-event-popper,
.el-popover.elv-transactions-drawer-address-popper,
.el-popover.elv-transactions-screening-events-popper,
.el-popover.elv-transactions-screening-contacts-popper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  min-width: v-bind('minWidth');
  width: auto !important;
  background: #ffffff;
  border: 1px solid #e4e7eb;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  .elv-transactions-screening-events-item {
    display: flex;
  }

  .elv-transactions-screening-events-purpose {
    display: flex;
    flex-direction: column;
    max-width: 179px;
    min-width: v-bind('purposeWidth');
    min-height: v-bind('purposeHeight');
    border-right: 1px solid #edf0f3;
  }

  .elv-transactions-screening-events-purpose__item {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background: #f9fafb;
    }

    &.elv-purpose-is-checked {
      background: #edf0f3;
    }

    .el-checkbox {
      width: 26px;
      padding-right: 0px;
      min-width: auto;
      display: v-bind('checkboxInputDisplay');
    }

    p {
      width: 100%;
      padding-left: 8px;
      margin: 0;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      line-height: 15px;
      font-weight: 500 !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      svg {
        fill: #d0d4d9;
      }
    }
  }

  .el-checkbox-group {
    width: auto;
    height: 100%;

    display: flex;
    flex-direction: column;

    .elv-transactions-screening-event-title {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 0px 8px;
      width: 100%;
      height: 32px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 14px;
      color: #838d95;
      margin: 0;
    }
  }

  .el-checkbox {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    padding-right: 8px;
    width: 100%;
    min-width: 150px;
    height: 32px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #1e2024;
    margin-right: 0px;

    &.is-checked {
      .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #1e2024;
      }
    }

    &.elv-purpose-is-checked {
      background: #edf0f3 !important;
    }

    &:not(.is-checked):hover {
      background: #f9fafb;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner {
      border-color: #1753eb;
      background-color: #1753eb;

      &:after {
        left: 3.5px;
      }
    }

    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      border-color: #1753eb;
      background-color: #fff;

      &::before {
        height: 2.5px;
        top: 4.5px;
        background-color: #1753eb;
      }
    }

    .el-checkbox__inner {
      width: 13.5px;
      height: 13.5px;
      box-sizing: border-box;
      border: 1.5px solid #d0d4d9;
      background: #fff;
    }

    .el-checkbox__input {
      width: 18px;
      height: 18px;
      display: v-bind('checkboxInputDisplay');
      justify-items: center;
      align-items: center;
      margin-left: 8px;
    }

    .el-checkbox__label {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 18px;
        height: 18px;
        display: block;
        margin-right: 8px;
      }

      span {
        width: 100%;
        line-height: 32px;
        font-weight: 500;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
